.page {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .page ul {
    list-style: none;
    padding: 0;
  }
  
  .page ul li {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  