/* Header section styles */
.image-container {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.page-title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: #fff;
  text-align: center;
  margin: 0;
}

.page-subtitle {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: #fff;
  text-align: center;
  margin: 0;
  padding-top: 10px;
}


/* Company intro section styles */
.company-intro {
  text-align: center;
  margin: 50px auto;
  max-width: 800px;
}

.company-intro h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #333;
}

.company-intro p {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.8;
}

.btn {
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
  border: 2px solid #007bff;
  color: #007bff;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  background-color: #007bff;
  color: #fff;
}

/* Why Choose section styles */
.why-choose {
  background-color: #f7f7f7;
  padding: 50px;
  text-align: center;
}

.why-choose h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #333;
}

.why-choose ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.why-choose li {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 30px;
  text-align: left;
}

.why-choose li:before {
  content: '• ';
  color: #007bff;
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

/* Quote section styles */
.quote-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.quote-text {
  font-size: 2rem;
  font-style: italic;
  color: #333;
  margin-bottom:
    20px;
}

.quote-source {
  font-size: 1.5rem;
  color: #666;
  margin-top: 10px;
}

.btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.5rem;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  transition: background-color 0.2s ease;
}

.btn:hover {
  background-color: #0069d9;
}