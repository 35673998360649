.products {
    margin: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
  }
  
  .products ul {
    list-style: none;
    padding: 0;
  }
  
  .products li {
    margin-bottom: 10px;
  }
  
  .products h3 {
    margin: 0;
  }
  
  .products p {
    margin: 0;
  }
  