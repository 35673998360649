.page {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input, textarea {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  textarea {
    height: 150px;
  }
  
  button[type="submit"] {
    padding: 5px 10px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 50px;
  }
  