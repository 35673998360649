.about {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 200px;
  padding: 30px;
  font-size: 18px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .about {
    font-size: 16px;
    padding: 20px;
  }
}
