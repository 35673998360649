
.logo-container {
  float: left;
  width: 60px;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 7px;
  height: 50px;
}

.logo-container img {
  height: 100%;
  width: 100%;
}

